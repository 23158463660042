import React, { Component } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

class Owl extends Component {
  render() {
    return (
      <div class="section-full related-products content-inner ">
        <div class="products-carousel">
          <Carousel
            autoPlay={this.props.deviceType !== 'mobile' ? true : false}
            responsive={responsive}
            arrows={false}
          >
            <div className="p-a15">
              <div class="item-box shop-item">
                <div class="item-img">
                  <img
                    src={require('./../../images/menu/glazed-almond-cookie.jpg')}
                    alt=""
                  />
                </div>
                <div class="item-info text-center">
                  <h5 class="item-title text-white">Algerian Cookies</h5>
                </div>
              </div>
            </div>
            <div className="p-a15">
              <div class="item-box shop-item">
                <div class="item-img">
                  <img
                    src={require('./../../images/menu/baklavas.jpg')}
                    alt=""
                  />
                </div>
                <div class="item-info text-center">
                  <h5 class="item-title text-white ">Baklavas</h5>
                </div>
              </div>
            </div>
            <div className="p-a15">
              <div class="item-box shop-item">
                <div class="item-img">
                  <img
                    src={require('./../../images/menu/fruit-sweets.jpg')}
                    alt=""
                  />
                </div>
                <div class="item-info text-center">
                  <h5 class="item-title text-white ">Fruit Sweets</h5>
                </div>
              </div>
            </div>
            <div className="p-a15">
              <div class="item-box shop-item">
                <div class="item-img">
                  <img
                    src={require('./../../images/menu/filled-dates.jpg')}
                    alt=""
                  />
                </div>
                <div class="item-info text-center">
                  <h5 class="item-title text-white ">Filled Dates</h5>
                </div>
              </div>
            </div>
            <div className="p-a15">
              <div class="item-box shop-item">
                <div class="item-img">
                  <img
                    src={require('./../../images/menu/customBoxes.jpg')}
                    alt=""
                  />
                </div>
                <div class="item-info text-center">
                  <h5 class="item-title text-white ">Ferrero Balls</h5>
                </div>
              </div>
            </div>
            <div className="p-a15">
              <div class="item-box shop-item">
                <div class="item-img">
                  <img
                    src={require('./../../images/menu/sweetbox2.jpg')}
                    alt=""
                  />
                </div>
                <div class="item-info text-center">
                  <h5 class="item-title text-white ">Sweet Boxes</h5>
                </div>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    );
  }
}

export default Owl;
