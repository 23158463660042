import React, { Component } from 'react';

var img4 = require('./../../images/background/bg4.jpg');

class Gallery extends Component {
  render() {
    return (
      <>
        <div class="page-content bg-white" style={{ marginTop: '6rem' }}>
          <div className="content-block">
            <div
              className="section-full content-inner "
              style={{
                backgroundImage: 'url(' + img4 + ')',
                backgroundSize: 'cover',
              }}
            >
              <div className="container">
                <div
                  className="dlab-bnr-inr-entry text-white    "
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '1rem',
                    marginBottom: '2rem',
                  }}
                >
                  <h1>Sweets Gallery </h1>
                </div>
              </div>

              <div className="container">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="item-box shop-item ">
                      <div className="item-img">
                        <img
                          src={require('./../../images/Sweets/baklavas.jpg')}
                          alt=""
                        />
                      </div>
                      <div className="item-info text-center text-white">
                        <h4 className="item-title">Baklavas</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="item-box shop-item">
                      <div className="item-img">
                        <img
                          src={require('./../../images/Sweets/glazed-almond-cookie.jpg')}
                          alt=""
                        />
                        <div className="price">Best Seller</div>
                      </div>
                      <div className="item-info text-center text-white">
                        <h4 className="item-title">Glazed Almond Cookie</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="item-box shop-item">
                      <div className="item-img">
                        <img
                          src={require('./../../images/Sweets/AlmondCigars.jpg')}
                          alt=""
                        />
                      </div>
                      <div className="item-info text-center text-white">
                        <h4 className="item-title">Hazelnut Shells</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="item-box shop-item">
                      <div className="item-img">
                        <img
                          src={require('./../../images/Sweets/AlmondCigars.jpg')}
                          alt=""
                        />
                      </div>
                      <div className="item-info text-center text-white">
                        <h4 className="item-title">Gazelled Horns</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="item-box shop-item">
                      <div className="item-img">
                        <img
                          src={require('./../../images/Sweets/AlmondCigars.jpg')}
                          alt=""
                        />
                      </div>
                      <div className="item-info text-center text-white">
                        <h4 className="item-title">Almond Cigars</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="item-box shop-item">
                      <div className="item-img">
                        <img
                          src={require('./../../images/Sweets/fruit-sweets.jpg')}
                          alt=""
                        />
                      </div>
                      <div className="item-info text-center text-white">
                        <h4 className="item-title">Fruit Sweets</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="item-box shop-item">
                      <div className="item-img">
                        <img
                          src={require('./../../images/Sweets/filled-dates.jpg')}
                          alt=""
                        />
                      </div>
                      <div className="item-info text-center text-white">
                        <h4 className="item-title">Filled Dates</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="item-box shop-item">
                      <div className="item-img">
                        <img
                          src={require('./../../images/Sweets/ferrero-ball.jpg')}
                          alt=""
                        />
                      </div>
                      <div className="item-info text-center text-white">
                        <h4 className="item-title">Ferrero Balls</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Gallery;
