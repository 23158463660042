import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const defaultAccordion = [
  {
    title: 'What do we offer ?',
    text: 'We offer a range of Algerian dessert delicacies ranging from Algerian cookies and pastries to various Algerian sweets. So, if you have an occasion or party coming up be sure to remember us for your Algerian Pastrys.',
  },
  {
    title: 'Where do we deliver ? ',
    text: 'As of today, We only do deliveries on the general Houston, Texas area',
  },
  { title: 'How can you order from our website?', text: <HowToOrder /> },
  { title: 'Detailed Steps for ordering by email', text: <OrderByEmail /> },
  { title: 'Detailed Steps for ordering by Instagram', text: <OrderByInsta /> },
];

const Orderfaq = () => {
  const [activeDefault, setActiveDefault] = useState(0);
  return (
    <>
      <div className="col-lg-8 col-md-7 m-b30">
        <div className="faq-area2">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-head text-left">
                <h3>Do You Have Any Questions?</h3>
                <p>
                  Please read these general questions and their answers we've
                  included below :
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="title-bx">
                <div className="icon-bx-xs">
                  <Link to={''} className="icon-cell text-white">
                    <i className="fa fa-comment"></i>
                  </Link>
                </div>
                <h4>Ask Us</h4>
              </div>
              <Accordion
                className="dlab-accordion faq-style2"
                defaultActiveKey="0"
              >
                {defaultAccordion.map((d, i) => (
                  <div className="panel">
                    <div className="acod-head">
                      <Accordion.Toggle
                        as={Link}
                        variant="link"
                        className={` ${activeDefault === i ? '' : 'collapsed'}`}
                        onClick={() =>
                          setActiveDefault(activeDefault === i ? -1 : i)
                        }
                        eventKey={`${i}`}
                      >
                        {d.title}
                      </Accordion.Toggle>
                    </div>
                    <Accordion.Collapse
                      eventKey={`${i}`}
                      className="acod-body collapse "
                    >
                      <div className="acod-content">{d.text}</div>
                    </Accordion.Collapse>
                  </div>
                ))}
              </Accordion>

              {/* <div className="dlab-accordion faq-style2" id="accordion001">
													<div className="panel">
														<div className="acod-head">
															<h6 className="acod-title"> 
																<Link to={''} data-toggle="collapse" data-target="#collapse1" aria-expanded="true"> 
																Web design aorem apsum dolor sit amet?</Link> 
															</h6>
														</div>
														<div id="collapse1" className="acod-body collapse show" data-parent="#accordion001">
															<div className="acod-content">
																<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
																<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
															</div>
														</div>
													</div>
													<div className="panel">
														<div className="acod-head">
															<h6 className="acod-title"> 
																<Link to={''} data-toggle="collapse" data-target="#collapse2" className="collapsed" aria-expanded="false">Graphic design aorem apsum dolor ?</Link> 
															</h6>
														</div>
														<div id="collapse2" className="acod-body collapse" data-parent="#accordion001">
															<div className="acod-content">
																<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
																<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
															</div>
														</div>
													</div>
												</div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function HowToOrder() {
  return (
    <>
      <p>
        As of right now, our website is still currently under construction and
        the ordering facilities from the site hasn't been implemented.
      </p>
      <p>
        You can still order by sending us an email and DM'ing our instagram page
        which is linked on the left side within the <b>QUICK CONTACT</b>{' '}
        section.
      </p>
    </>
  );
}

function OrderByEmail() {
  return (
    <>
      <p>
        <b>Steps:</b>
      </p>
      <ol>
        <li>
          Firstly, Navigate to the <b>contact us</b> page and click on the{' '}
          <i className="ti-email ti-lg"></i> icon under the <b>Quick Contact</b>{' '}
          section or alternatively, You can copy the email address manually and
          send an email from your browser
        </li>
        <li>
          Clicking on the email icon will forward to your default email
          application or browser and have a template ready for sending.
        </li>
        <li>
          Lastly, just mention the items you would like to order in the email
          body and send it. For ordering box items just include a simple box
          title select your preferred sweets.
        </li>
      </ol>
    </>
  );
}

function OrderByInsta() {
  return (
    <>
      <p>
        <b>Steps:</b>
      </p>
      <ol>
        <li>
          Firstly, Navigate to the <b>contact us</b> page and click on the{' '}
          <i className="fa fa-instagram fa-lg"></i> icon under the{' '}
          <b>Quick Contact</b> section or alternatively, You can visit our
          instagram page manually and send us a dm for ordering.
        </li>
        <li>
          Clicking on the icon will forward to our instagram page within the
          instagram app from your phone or the site from your browser.
        </li>
        <li>
          Lastly, just mention the items you would like to order in the dm and
          send it. We will reply back as soon as we can.
        </li>
      </ol>
    </>
  );
}

export default Orderfaq;
