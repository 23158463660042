import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Gototop from './gototop';


var bgfoter = require('./../../images/background/bg2.jpg');

class Footer extends Component {
  render() {
    return (
      <footer
        className="site-footer "
        style={{
          backgroundImage: 'url(' + bgfoter + ')',
          backgroundSize: 'cover',
        }}
      >
        <Gototop />
        <div className="footer-top bg-line-top">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                <div className="widget widget_getintuch">
                  <h5 className="footer-title text-white">Contact Us</h5>
                  <ul>
                    <li>
                      <i className="fa fa-map-marker"></i>
                      <p>Houston, Texas</p>
                    </li>
                    <li>
                      <i className="fa fa-phone"></i>
                      <p>Women Owned Business</p>
                    </li>
                    {/* <li>
											<i className="fa fa-mobile"></i> 
											<p>(+91) 0800-123456</p>
										</li> */}
                    <li>
                      <i className="fa fa-envelope"></i>
                      <p>kimkimspastry@gmail.com</p>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                <div className="widget widget_services border-0">
                  <h4 className="footer-title">Quick Links</h4>
                  <ul className="list-2">
                    <li>
                      <Link to={'/'}>Home</Link>
                    </li>
                    <li>
                      <Link to={'/aboutus'}>About</Link>
                    </li>
                    <li>
                      <Link to={'/ourmenu'}>Our Menu</Link>
                    </li>
                    <li>
                      <Link to={'/contactus'}>FAQ</Link>
                    </li>
                    {/* <li>
                      <Link to={'/blog-grid-2'}>Blog</Link>
                    </li>
                    <li>
                      <Link to={'/shop'}>Shop</Link>
                    </li> */}
                    <li>
                      <Link to={'/contactus'}>Contact</Link>
                    </li>
                    {/* <li>
                      <Link to={'/team'}>Team</Link>
                    </li>
                    <li>
                      <Link to={'/calendar'}>Calendar</Link>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                <div className="widget border-0">
                  <h4 className="footer-title">Our Socials</h4>
                  {/* <p className="m-b20">
                    Our support available to help you 24 hours a day, seven days
                    a week.
                  </p> */}
                  <ul className="work-hour-list">
                    <li>
                      <span className="day">
                        <span>Instagram</span>
                      </span>
                      <a
                        href={'https://www.instagram.com/kimkimspastry/?hl=en'}
                      >
                        {/* <i className="fa fa-instagram fa-lg"></i> */}
                        <i>@kimkimspastry</i>
                      </a>
                    </li>
                    <li>
                      <span className="day" >
                        <a >Tiktok</a>
                      </span>
                      <a
                        href={'https://www.tiktok.com/@kimkimspastry'}
                      >

                        <i>@kimkimspastry</i>

                      </a>
                    </li>
                    {/* <li>
                      <span className="day">
                        <span>Saturday</span>
                      </span>
                      <span className="timing">10AM - 5PM</span>
                    </li>
                    <li>
                      <span className="day">
                        <span>Sunday</span>
                      </span>
                      <span className="timing">Closed</span>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 text-left">
                {' '}
                <span>Copyright © 2022 KIMKIMS. all rights reserved.</span>{' '}
              </div>
              <div className="col-lg-6 col-md-6 text-right">
                <div className="widget-link">
                  <ul>
                    <li>
                      
                      <Link to={'aboutus'}>About Us</Link>
                    </li>
                    <li>
                      <Link to={'contactus'}>Faq</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
