import React, { Component } from 'react';
import Gallery from '../Pages/Gallery';

class Menuitem extends Component {
  render() {
    return (
      <>
        <div className="row">
        {/* style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: '2rem',
              }} */}
          <div className="col-lg-6">
            <ul className="menu-list m-b0">
              <li>
                <small>TOP SELLER</small>
                <div className="menu-item">
                  <h4>
                    <span>
                      <i className="la la-check-circle-o"></i> Baklava rolls
                    </span>
                  </h4>
                </div>
                {/* <p className="description">
                  Lorem ipsum dolor sit amet consectetur elit
                </p> */}
              </li>
              <li>
                <div className="menu-item">
                  <h4>
                    <span>
                      <i className="la la-check-circle-o"></i>Hazelnut shells
                    </span>
                  </h4>
                </div>
                {/* <p className="description">
                  Lorem ipsum dolor sit amet consectetur elit
                </p> */}
              </li>
              <li>
                <div className="menu-item">
                  <h4>
                    <span>
                      <i className="la la-check-circle-o"></i>Gazelle Horns
                    </span>
                  </h4>
                </div>
                {/* <p className="description">
                  Lorem ipsum dolor sit amet consectetur elit
                </p> */}
              </li>
              <li>
                <div className="menu-item">
                  <h4>
                    <span>
                      <i className="la la-check-circle-o"></i>Glazed almond
                      cookie
                    </span>
                  </h4>
                </div>
                {/* <p className="description">
                  Lorem ipsum dolor sit amet consectetur elit
                </p> */}
              </li>
            </ul>
          </div>
          {/* change column */}
          <div className="col-lg-6" >
            <ul className="menu-list">
              <li>
                <small>TOP SELLER</small>
                <div className="menu-item">
                  <h4>
                    <span>
                      <i className="la la-check-circle-o"></i>Almond cigars
                    </span>
                  </h4>
                </div>
                {/* <p className="description">
                  Lorem ipsum dolor sit amet consectetur elit
                </p> */}
              </li>
              <li>
                <div className="menu-item">
                  <h4>
                    <span>
                      <i className="la la-check-circle-o"></i>Fruit sweets
                    </span>
                  </h4>
                </div>
                {/* <p className="description">
                  Lorem ipsum dolor sit amet consectetur elit
                </p> */}
              </li>
              <li>
                <div className="menu-item">
                  <h4>
                    <span>
                      <i className="la la-check-circle-o"></i>Filled dates
                    </span>
                  </h4>
                </div>
                {/* <p className="description">
                  Lorem ipsum dolor sit amet consectetur elit
                </p> */}
              </li>
              <li>
                <div className="menu-item">
                  <h4>
                    <span>
                      <i className="la la-check-circle-o"></i>Ferrero balls
                    </span>
                  </h4>
                </div>
                {/* <p className="description">
                  Lorem ipsum dolor sit amet consectetur elit
                </p> */}
              </li>
            </ul>
          </div>
        </div>
        {/* //
        //
        //
        //
        // */}
        <div className="row">
          <div className="container">
            <div
              className="dlab-bnr-inr-entry"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '2rem',
              }}
            >
              <h1>Sweet Boxes</h1>
            </div>
          </div>
          <div className="col-lg-3"></div>
          <ul className="menu-list">
            <li>
              <div
                className="menu-item"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <h4>
                  <span>
                    <i className="la la-check-circle-o"></i> KimKims' Gourmet
                    Box
                  </span>
                </h4>
              </div>
              <p className="description">
                Contains 12 pieces of our Algerian Prestige Sweets.Sweets can be
                chosen from the menu
              </p>
            </li>

            <li>
              <div
                className="menu-item"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <h4>
                  <span>
                    <i className="la la-check-circle-o"></i> KimKims' Gourmet
                    Box
                  </span>
                </h4>
              </div>
              <p className="description">
                Contains 12 pieces of our Algerian Prestige Sweets.Sweets can be
                chosen from the menu
              </p>
            </li>

            <li>
              <div
                className="menu-item"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <h4>
                  <span>
                    <i className="la la-check-circle-o"></i> KimKims' Gourmet
                    Box
                  </span>
                </h4>
              </div>
              <p className="description">
                Contains 16 pieces of our Algerian Prestige Sweets from our
                menu, of your choosing. <small>(min 16 pcs)</small>
              </p>
            </li>
          </ul>
        </div>
        <Gallery />
      </>
    );
  }
}

export default Menuitem;
