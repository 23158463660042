import React, { Component } from 'react';
import { BrowserRouter, HashRouter, Route, Switch } from 'react-router-dom';

import Index1 from './Pages/Index1';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Error from './Pages/Error';
import Faq from './Pages/Faq';
import Gallery from './Pages/Gallery';
import Ourmenustyle1 from './Pages/Ourmenustyle1';
import Ourservices from './Pages/Ourservices';
import Shop from './Pages/Shop';

class Markup extends Component {

	render() {
		return (
			<HashRouter>
					<Switch>

						<Route path='/' exact component={Index1} />

						<Route path='/aboutus' exact component={About} />
						<Route path='/our-services' exact component={Ourservices} />
						{/* <Route path='/faq' exact component={Faq} /> */}
						{/* <Route path='/error-404' exact component={Error} /> */}
						<Route path='/shop' exact component={Shop} />
						<Route path='/Gallery' exact component={Gallery} />
						<Route path='/contactus' exact component={Contact} />
						<Route path='/ourmenu' exact component={Ourmenustyle1} />
						<Route path='/error404' exact component={Error} />
						{/* Created but unused */}
						{/* <Route path='/booking' exact component ={Booking} />*/}
						{/* <Route path='/shop' exact component ={Shop} />
					<Route path='/shop-sidebar' exact component ={Shopsidebar} />
					<Route path='/shop-product-details' exact component ={Shopproduct} />					
					<Route path='/shop-cart' exact component ={Shopcart} />					
					<Route path='/shop-wishlist' exact component ={Shopwishlist} />
					<Route path='/shop-checkout' exact component ={Shopchekout} />
					<Route path='/shop-login' exact component ={Shoplogin} />
					<Route path='/shop-register' exact component ={Shopregister} /> */}

					</Switch>
				
			</HashRouter> 
		)

	}
}

export default Markup;