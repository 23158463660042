import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Footer from './../Layout/Footer';
import Header from './../Layout/Header';

import Owl from './../Element/Owl';
import Slider from './../Element/Slider';

//Images
var img1 = require('./../../images/background/bg5.jpg');
var serblog1 = require('./../../images/our-services/ALGERIAN_SWEETS.png');
var serblog2 = require('./../../images/our-services/AlgerianGlazedcookie.png');
var serblog3 = require('./../../images/our-services/GLAZED_ALMOND_COOKIE.png');
var serblog4 = require('./../../images/our-services/DOLCE_DE_LETE.png');
var img3 = require('./../../images/background/bg5.jpg');
var img4 = require('./../../images/background/bg4.jpg');

class Index1 extends Component {
  render() {
    return (
      <>
        <Header />
        

        <div class="page-content bg-white" style={{ marginTop: '6rem' }}>
          <div className="content-block">
            <Slider />
            <div
              className="section-full content-inner-3"
              style={{
                backgroundImage: 'url(' + img1 + ')',
                backgroundSize: '100%',
              }}
            >
              <div className="container">
                <div className="row service-area1">
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                    <div
                      className="icon-bx-wraper text-center service-box1"
                      style={{ backgroundImage: 'url(' + serblog1 + ')' }}
                    >
                      <div className="icon-content">
                        <h2 className="dlab-tilte text-white">
                          Algerian Sweets
                        </h2>
                        {/* <p>Lorem Ipsum is simply</p> */}
                        <div className="dlab-separator style1 bg-primary"></div>
                        <Link to={'/ourmenu'} className="btn btnhover">
                          More details{' '}
                          <i className="fa fa-angle-double-right m-l5"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                    <div
                      className="icon-bx-wraper text-center service-box1"
                      style={{ backgroundImage: 'url(' + serblog2 + ')' }}
                    >
                      <div className="icon-content">
                        <h2 className="dlab-tilte text-white">
                          Algerian Glazed Cookie
                        </h2>
                        {/* <p>Lorem Ipsum is simply</p> */}
                        <div className="dlab-separator style1 bg-primary"></div>
                        <Link to={'/ourmenu'} className="btn btnhover">
                          More details{' '}
                          <i className="fa fa-angle-double-right m-l5"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                    <div
                      className="icon-bx-wraper text-center service-box1"
                      style={{ backgroundImage: 'url(' + serblog3 + ')' }}
                    >
                      <div className="icon-content">
                        <h2 className="dlab-tilte text-white">
                          Glazed Almond Cookie
                        </h2>
                        {/* <p>Lorem Ipsum is simply </p> */}
                        <div className="dlab-separator style1 bg-primary"></div>
                        <Link to={'/ourmenu'} className="btn btnhover">
                          More details{' '}
                          <i className="fa fa-angle-double-right m-l5"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                    <div
                      className="icon-bx-wraper text-center service-box1"
                      style={{ backgroundImage: 'url(' + serblog4 + ')' }}
                    >
                      <div className="icon-content">
                        <h2 className="dlab-tilte text-white">Dolce De Lete</h2>
                        {/* <p>Lorem Ipsum is simply </p> */}
                        <div className="dlab-separator style1 bg-primary"></div>
                        <Link to={'/ourmenu'} className="btn btnhover">
                          More details{' '}
                          <i className="fa fa-angle-double-right m-l5"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-head mb-0 text-center">
                      <div
                        style={{ marginBottom: '1px' }}
                        className="icon-bx icon-bx-m"
                      >
                        <img
                          style={{ width: '300px', height: '300px' }}
                          src={require('./../../images/cake1.png')}
                          alt=""
                        />
                      </div>
                      <h3>
                        Welcome to KimKims Pastry! The best Algerian Sweets in
                        Houston.
                      </h3>
                      <h1>
                        <Link to={'/aboutus'}>Our Story</Link>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="section-full content-inner service-area2 bg-img-fix bg-line-top bg-line-bottom"
              style={{
                backgroundImage: 'url(' + img4 + ')',
                backgroundSize: 'cover',
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-head text-center">
                      <h2 className="text-white">What Do We Offer For You?</h2>
                      <p className="text-bold text-white">
                        At KimKims pastry you will find a range of Algerian
                        dessert delicacies for you to enjoy. We offer sweets and
                        pastries for all sorts of occasions and festivals. So,
                        Check out our offerings and get ready to satisfy those
                        sweet cravings.
                      </p>
                      <div className="dlab-separator style1 bg-primary"></div>
                      <h3 className="text-white">
                        Have a look at our offerings
                      </h3>
                    </div>
                    <Owl />
                    <Link
                      to={'/ourmenu'}
                      className="link-light text-white"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      Check out our menu for more details.
                      <i className="fa fa-angle-double-right m-15"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="section-full bg-white"
              style={{
                backgroundImage: 'url(' + img3 + ')',
                backgroundSize: '100%',
              }}
            >
              <div className="container"></div>
              <div className="container">
                <div className="row client-area1 p-t80">
                  {/* <OurPartners  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Index1;
